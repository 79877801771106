import * as React from "react";
import Layout from "../../components/layout/layout.jsx";
import {
    consultation,
    consultationContent,
} from "../../pages/doctors/consult/consult.module.scss";

export default function ConsultDoctor({ slug, language }) {
    const videoConsultation = "https://digitalclinicukraine.doxy.me/";
    let consultDoctorLink = `${videoConsultation}${slug}?l=${language}`;
    let allowCameraAndMicrophone = `camera ${consultDoctorLink}; microphone ${consultDoctorLink};`
    return (
        <Layout>
            <iframe
                className={consultationContent}
                title="Consultation"
                id="virtual-clinic"
                src={consultDoctorLink}
                allow={allowCameraAndMicrophone}
            />
        </Layout>
    );
}
