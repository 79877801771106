import React from "react";
import { graphql } from "gatsby";
import ConsultDoctor from "../../../components/consult-doctor/index";
import SEO from "../../../components/seo"

export const query = graphql`
    query ConsultDoctor($language:String!) {
        allContentfulDoctorProfile(filter: {node_locale: {eq: $language}}) {
            nodes {
                slug
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Consult() {
    return <>
            <SEO title="Consult" />
                <ConsultDoctor></ConsultDoctor>
            </>;
}
